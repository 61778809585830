import {useEffect} from 'react'

export const useEmbedMessaging = (id) => {
  useEffect(() => {
    const onResize = () => {
      window.top.postMessage({height: document.body.scrollHeight, id}, '*')
    }
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])
}