import * as styles from 'src/css/embed.module.css'
import PostCollection from 'src/components/post-collection'
import React from 'react'
import {graphql} from 'gatsby'
import {useEmbedMessaging} from 'src/utils/embed'

const CaseStudiesEmbed = ({data, pageContext}) => {
  const {caseStudies} = data
  const {country} = pageContext || {}

  useEmbedMessaging('case-studies')

  return (
    <div className={styles.postcards}>
      <PostCollection posts={caseStudies.nodes} layout="cover" titleComponent="h3" linkComponent={EmbedLink} country={country} slider />
    </div>
  )
}

export default CaseStudiesEmbed

const EmbedLink = ({to, ...props}) => (
  <a href={to} target="_parent" {...props} />
)

export const query = graphql`
  query {
    caseStudies: allStrapiArticle(
      filter: {postType: {slug: {eq: "case-studies"}}}
      sort: {fields: publishedAt, order: DESC}
      limit: 12
    ) {
      nodes {
        ...PostcardCover
      }
    }
  }
`;